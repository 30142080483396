import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getMonth,
  getYear,
  startOfMonth,
} from "date-fns";
import { ScheduleDetailStatusSchema } from "../generated";
import { SCHEDULE_DETAIL_STATUS } from "../models/scheduleReservationFrame.model";
import { FLG_MONDAY, FLG_SUNDAY } from "../constants";


export const NOW = new Date();
export const THIS_YEAR = getYear(NOW);
export const THIS_MONTH = getMonth(NOW) + 1;

export const getCalendarArray = (date: Date) => {
  const sundays = eachWeekOfInterval({
    start: startOfMonth(date), // 月初
    end: endOfMonth(date), // 月末
  });

  // 月〜土までの日付を取得
  return sundays.map((sunday) =>
    eachDayOfInterval({ start: sunday, end: endOfWeek(sunday) })
  );
};

export const getStartDayOfTheWeek = (targetDate: Date) => {
  // targetDateが現在月の場合、今週を表示する
  const today = new Date();
  if (targetDate.getMonth() == today.getMonth()) {
    targetDate = today;
  }

  const weekday = targetDate.getDay();
  // targetDateが日曜の場合、前週の月曜を取得するため7を加算
  const adjustedWeekday = weekday == FLG_SUNDAY ?  weekday + 7 : weekday;
  const monday =  new Date(targetDate);
  monday.setDate(targetDate.getDate() - (adjustedWeekday - FLG_MONDAY));
  return monday;
};

export const makeEventDayColor = (events: any[], date: Date) => {
  const d = format(date, "yyyy-MM-dd");
  for (let i = 0; i < events.length; i++) {
    if (d === events[i].dates) {
      return ["#FEFAD0", "black"];
    }
  }
  return ["white", "black"];
};

export const filteredEvents = (events: any[], date: Date) => {
  const d = format(date, "yyyy-MM-dd");
  const filteredEvent = events.filter((e) => {
    return e.dates === d;
  });

  return filteredEvent;
};

export const getReservationNum = (scheduleDetails: ScheduleDetailStatusSchema[]) => {
  return scheduleDetails?.filter(
    x => [
      SCHEDULE_DETAIL_STATUS.RESERVED,
      SCHEDULE_DETAIL_STATUS.APPROVED,
      SCHEDULE_DETAIL_STATUS.TEMPORARY,
    ].indexOf(x.status) > -1
  ).length ?? 0;
};

export const getApprovedNum = (scheduleDetails: ScheduleDetailStatusSchema[]) => {
  return scheduleDetails?.filter(x => x.status == SCHEDULE_DETAIL_STATUS.APPROVED).length ?? 0;
};